import { required, email, integer } from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { komshipAxiosIns, axiosIns } from '@/libs/axios'

export default {
  components: {
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email,
      required,
      integer,
      loadingPage: true,
      btnSubmitDisabled: false,
      filteredOptionsDesti: [],
      queryDestination: '',
      selectedDestination: null,
      inputPropsDestination: {
        id: 'inputPropsDestination',
        class: 'form-control',
        placeholder: 'Masukan Kode Pos/Kecamatan',
      },
      limitOptDestination: 10,
      optionDestination: [],
      optionsKetersediaan: [
        { text: 'Penuh', value: 0 },
        { text: 'Tersedia', value: 1 },
      ],
      optionsGender: [
        { value: 'Laki-laki', text: 'Laki-laki' },
        { value: 'Perempuan', text: 'Perempuan' },
      ],
      disabledField: {
        email: true,
        username: true,
        warehouse_name: true,
        avability: true,
        pic_name: true,
        pic_phone: true,
        description: true,
        image_warehouse: true,
        image_logo_url: true,
        owner: true,
        gender: true,
        phone_number: true,
        nik: true,
        image_ktp_url: true,
        destination_id: true,
        detail_address: true,
        building_area: true,
        building_type: true,
        ownership: true,
      },
      dataAkun: {
        email: '',
        username: '',
        id: '',
      },
      dataFulfillment: {
        warehouse_name: '',
        avability: null,
        pic_name: '',
        pic_phone: '',
        description: '',
        image_warehouse: null,
        image_logo_url: null,
        service_status: '',
        email: '',
      },
      dataOwner: {
        owner: '',
        gender: null,
        phone_number: '',
        nik: '',
        image_ktp_url: null,
        partner_verification: '',
      },
      dataProperti: {
        destination_id: '',
        detail_address: '',
        building_area: 0,
        building_type: null,
        ownership: null,
        warehouse_verification: '',
      },
      isOnEdit: false,
      statusProfile: '',
      dataStatusObj: {
        aktif: 'Aktif',
        nonaktif: 'Non - Aktif',
        unverified: 'Belum Diverifikasi',
      },
      actionStatusType: {
        isActivated: 'isActivated',
        aktif: 'aktif',
        nonaktif: 'nonaktif',
      },
      imageFieldFormType: {
        fulfillmentLogo: 'fulfillmentLogo',
        fulfillmentWarehouse: 'fulfillmentWarehouse',
        ownerKTP: 'ownerKTP',
      },
      changedImageData: {
        ktp: null,
        warehouse: [],
        logo: null,
        warehouseDeleted: [],
      },
      prevImg: {
        logo: null,
        warehouse: null,
        ktp: null,
      },
      edit_Fulfillment: this.$route.params.editFulfillment,
      phoneCodeList: ['+62'],
      phoneCode: '+62',
      requireCustomerPhone: false,
      isWhatsapp: null,
      is_whatsapp: this.isWhatsapp === 'valid' ? 1 : 0,
      messageErrorPhone: false,
      customerPhone: '',
      customerPhonePasteMode: false,
      disableVerifEmail: false,
      loadingVerifEmail: false,
      disableBtn: false,
      loadingBtn: false,
    }
  },
  watch: {
    queryDestination: {
      handler(val) {
        if (!val) {
          this.selectedDestination = ''
          this.dataProperti.destination_id = null
        }
        this.fetchDataDestination()
      },
    },
  },
  computed: {
    ...mapState('kompackAdmin', ['selecOptData']),
    statuscomputed() {
      if (this.dataFulfillment.service_status === 'Non Active') {
        return `<span class="d-flex align-items-center bg-[#FFEDED] rounded-md pl-[10px] pr-[10px] font-bold text-red-500">
              <span class="w-4 h-4 rounded-full bg-red-500 mr-0.5"></span>Non-Aktif</span>`
      }
      if (this.dataFulfillment.service_status === 'Active') {
        return `<span class="d-flex align-items-center bg-[#DCF3EB] rounded-md pl-[10px] pr-[10px] font-bold text-green-500">
              <span class="w-4 h-4 rounded-full bg-green-500 mr-0.5"></span>Aktif</span>`
      }
      return `<span class="d-flex align-items-center bg-yellow-100 rounded-md pl-[10px] pr-[10px] font-bold text-warning">
            <span class="w-4 h-4 rounded-full bg-warning mr-0.5"></span>Belum Terverifikasi</span>`
    },
  },
  beforeMount() {
    this.$store.dispatch('kompackAdmin/init')
  },
  mounted() {
    this.getDataDetailMitra()
    this.editFulfillment()
    this.checkWhatsapp()
  },
  methods: {
    showModalBatal() {
      this.$bvModal.show('modal-tambahmitra-warning')
    },
    ServiceStatus(dt) {
      this.disableBtn = true
      this.loadingBtn = true
      if (dt === 'Non Active') {
        this.service_status = 'Active'
      }
      if (dt === 'Active') {
        this.service_status = 'Non Active'
      }
      this.$http
        .put(
          `kompack/warehouse/update/service?mitra_id=${this.$route.params.id}&type_service=${this.service_status}`,
        )
        .then(({ data }) => {
          this.disableBtn = true
          this.loadingBtn = false
          if (data.status === 'success' && data.code === 200) {
            this.getDataDetailMitra()
          }
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              text: '',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.disableBtn = true
          this.loadingBtn = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail data mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    // verificationOrAktifData(dataParams, type = '') {
    //   let endpoint = ''
    //   switch (type) {
    //     case this.actionStatusType.partner:
    //       endpoint = `/kompack/warehouse/verification-partner-warehouse?mitra_id=${this.$route.params.id}&is_verification=${dataParams}`
    //       this.dataOwner.partner_verification = dataParams
    //       break
    //     case this.actionStatusType.warehouse:
    //       endpoint = `kompack/warehouse/verification-warehouse?id_warehouse=${this.dataFulfillment.warehouse_id}&is_verification=${dataParams}`
    //       this.dataProperti.warehouse_verification = dataParams
    //       break
    //     default:
    //       break
    //   }
    //   this.$http.put(endpoint)
    //     .then(({ data }) => {
    //       if (data.status === 'success' && data.code === 200) {
    //         this.getDataDetailMitra()
    //       }
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: 'Success',
    //           text: '',
    //           icon: 'AlertCircleIcon',
    //           variant: 'success',
    //         },
    //       })
    //     })
    //     .catch(() => {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: 'Failed',
    //           text: 'Galat detail data mitra gudang',
    //           icon: 'AlertCircleIcon',
    //           variant: 'danger',
    //         },
    //       })
    //     })
    // },
    getDataDetailMitra() {
      this.$http
        .get(`/kompack/warehouse/${this.$route.params.id}`)
        .then(async ({ data }) => {
          this.dataAkun = {
            id: data.data.id,
            user_id: data.data.user_id,
            email: data.data.email,
            username: data.data.username,
          }
          this.dataFulfillment = {
            warehouse_name: data.data.warehouse_name,
            warehouse_id: data.data.warehouse_id,
            avability: data.data.availability,
            pic_name: data.data.pic_name,
            pic_phone: data.data.pic_phone,
            description: data.data.description,
            image_warehouse: data.data.image_warehouse,
            image_logo_url: data.data.image_logo_url,
            service_status: data.data.service_status,
            email: data.data.email,
          }
          this.prevImg.ktp = data.data.image_ktp_url
          this.prevImg.logo = data.data.image_logo_url
          this.prevImg.warehouse = data.data.image_warehouse.map(
            x => x.image_url,
          )
          this.dataOwner = {
            owner: data.data.owner,
            gender: data.data.gender,
            phone_number: data.data.phone_number,
            nik: data.data.nik,
            partner_verification: data.data.partner_verification,
            image_ktp_url: data.data.image_ktp_url,
          }
          this.dataProperti = {
            detail_address: data.data.detail_address,
            building_area: data.data.building_area,
            building_type: data.data.building_type,
            ownership: data.data.ownership,
            warehouse_verification: data.data.warehouse_verification,
          }
          this.fetchDataDestinationOne(data.data.destination_id)
          this.getStatusDataProfile(
            data.data.partner_verification,
            data.data.warehouse_verification,
            data.data.service_status,
          )
          this.$nextTick(() => {
            this.loadingPage = false
          })
          this.changedImageData.warehouse = []
          await this.checkWhatsapp(data.data.pic_phone)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail data mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchDataDestinationOne(dataId) {
      this.$http('/kompack/destination', { params: { destination_id: dataId } })
        .then(({ data }) => {
          this.dataProperti.destination_id = data.data.id
          this.queryDestination = data.data.label
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat tambah data mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    savedatalist() {
      this.btnSubmitDisabled = true
      this.$refs.tambahlistdata.validate().then(success => {
        if (success) {
          if (
            this.dataOwner.image_ktp_url
            && this.dataFulfillment.image_warehouse
            && this.dataFulfillment.image_logo_url
          ) {
            //
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Galat',
                text: 'Check ada file yang belum diupload',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            this.btnSubmitDisabled = false
          }
          // body data
          const formData = new FormData()
          formData.append('id', this.dataAkun.id)
          formData.append('user_id', this.dataAkun.user_id)
          formData.append('email', this.dataAkun.email)
          formData.append('username', this.dataAkun.username)
          formData.append('owner', this.dataOwner.owner)
          formData.append('gender', this.dataOwner.gender)
          formData.append('phone_number', this.dataOwner.phone_number)
          formData.append('nik', this.dataOwner.nik)
          formData.append('image_ktp_url', this.changedImageData.ktp) // string ($binary)
          formData.append('image_logo', this.changedImageData.logo) // string ($binary)
          formData.append('name', this.dataFulfillment.warehouse_name)
          formData.append('warehouse_id', this.dataFulfillment.warehouse_id)
          formData.append('availability', this.dataFulfillment.avability)
          formData.append('pic_name', this.dataFulfillment.pic_name)
          formData.append('pic_phone', this.dataFulfillment.pic_phone)
          formData.append('description', this.dataFulfillment.description)
          if (this.changedImageData.warehouse) {
            this.changedImageData.warehouse.forEach(x => {
              formData.append('image_warehouse_insert[]', x)
            })
          } else {
            //
          }
          if (this.changedImageData.warehouseDeleted) {
            this.changedImageData.warehouseDeleted.forEach(x => {
              formData.append('image_warehouse_delete[]', x)
            })
          } else {
            //
          }
          formData.append(
            'destination_id',
            Number.isNaN(parseInt(this.dataProperti.destination_id, 10))
              ? this.dataProperti.destination_id
              : parseInt(this.dataProperti.destination_id, 10),
          )
          formData.append('detail_address', this.dataProperti.detail_address)
          formData.append(
            'building_area',
            Number.isNaN(parseInt(this.dataProperti.building_area, 10))
              ? 0
              : parseInt(this.dataProperti.building_area, 10),
          )
          formData.append('building_type', this.dataProperti.building_type)
          formData.append('ownership', this.dataProperti.ownership)

          // calling API untuk tambah mitra gudang
          this.$http
            .post('/kompack/warehouse/update', formData, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            .then(({ data }) => {
              // masuk data tidak error maka munculkan popup success
              this.$bvModal.show('modal-tambahmitra-success')
              this.btnSubmitDisabled = false
              this.getDataDetailMitra()
            })
            .catch(err => {
              if (err?.response?.data) {
                const dataError = Object.values(
                  err.response.data.errors,
                ).map(x => x.join())
                dataError.forEach(dt => {
                  this.$toast({
                    component: ToastificationContentVue,
                    props: {
                      title: 'Galat',
                      text: dt,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Failed',
                  text: 'Galat ubah data mitra gudang',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
              this.btnSubmitDisabled = false
            })
        } else {
          // jika ada error ketika validasi
          this.btnSubmitDisabled = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat ada data yang kurang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    editdataAll(boolData) {
      this.isOnEdit = !this.isOnEdit
      const newDataDisabled = {}
      Object.keys(this.disabledField).map(dt => {
        newDataDisabled[dt] = boolData
        return dt
      })
      this.disabledField = newDataDisabled
      this.getDataDetailMitra()
    },
    editFulfillment() {
      if (this.edit_Fulfillment === true) {
        this.editdataFulfillment()
      }
    },
    editdataFulfillment() {
      this.isOnEdit = true
      this.disabledField = {
        ...this.disabledField,
        warehouse_name: !this.disabledField.warehouse_name,
        avability: !this.disabledField.avability,
        pic_name: !this.disabledField.pic_name,
        pic_phone: !this.disabledField.pic_phone,
        description: !this.disabledField.description,
        image_warehouse: !this.disabledField.image_warehouse,
        image_logo_url: !this.disabledField.image_logo_url,
      }
    },
    previewLogo(evChange, type) {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF')
      } else {
        const [dataimg] = evChange.target.files
        let url = null
        const multiFile = []
        switch (type) {
          case this.imageFieldFormType.fulfillmentLogo:
            this.dataFulfillment.image_logo_url = dataimg
            this.changedImageData.logo = dataimg
            url = URL.createObjectURL(dataimg)
            this.prevImg.logo = url
            break
          case this.imageFieldFormType.fulfillmentWarehouse:
            if (this.prevImg.warehouse.length < 8) {
              evChange.target.files.forEach(fl => {
                multiFile.push(fl)
              })
              url = []
              multiFile.forEach(x => {
                if (x instanceof File) {
                  url.push(URL.createObjectURL(x))
                } else {
                  url.push(x)
                }
              })
              if (this.prevImg.warehouse.length + multiFile.length > 8) {
                this.changedImageData.warehouse = [
                  ...this.changedImageData.warehouse,
                ]
                this.prevImg.warehouse = [...this.prevImg.warehouse]
                this.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Galat',
                    text: 'Tidak bisa unggah lebih dari 8 berkas gambar',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                })
              } else {
                this.changedImageData.warehouse = [
                  ...this.changedImageData.warehouse,
                  ...multiFile,
                ]
                this.prevImg.warehouse = [...this.prevImg.warehouse, ...url]
              }
            }
            break
          case this.imageFieldFormType.ownerKTP:
            this.dataOwner.image_ktp_url = dataimg
            this.changedImageData.ktp = dataimg
            url = URL.createObjectURL(dataimg)
            this.prevImg.ktp = url
            break
          default:
            break
        }
      }
    },
    getStatusDataProfile(
      partnerVerification,
      warehouseVerification,
      serviceStatus,
    ) {
      let datastatus = ''
      if (!(Boolean(partnerVerification) && Boolean(warehouseVerification))) {
        if (serviceStatus === this.dataStatusObj.unverified) {
          this.statusProfile = this.dataStatusObj.unverified
          datastatus = this.dataStatusObj.unverified
        }
      }
      if (serviceStatus === 'nonaktif') {
        this.statusProfile = this.dataStatusObj.nonaktif
        datastatus = this.dataStatusObj.nonaktif
      }
      if (
        Boolean(partnerVerification)
        && Boolean(warehouseVerification)
        && serviceStatus === 'aktif'
      ) {
        this.statusProfile = this.dataStatusObj.aktif
        datastatus = this.dataStatusObj.aktif
      }
      return datastatus
    },
    getDestinationValue(suggestion) {
      const { item } = suggestion
      this.selectedDestination = item
      this.dataProperti.destination_id = item.id
      return item.label
    },
    onInputChangeDestination(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filtered = this.optionDestination
        .filter(
          item => item.label.toLowerCase().indexOf(text.toLowerCase()) > -1,
        )
        .slice(0, this.limitOptDestination)
      this.filteredOptionsDesti = [
        {
          data: filtered,
        },
      ]
    },
    fetchDataDestination() {
      const filtered = this.optionDestination
        .filter(
          item => item.label
            .toLowerCase()
            .indexOf(this.queryDestination.toLowerCase()) > -1,
        )
        .slice(0, this.limitOptDestination)
      if (!filtered.length) {
        this.$http('/kompack/destination', {
          params: { search: this.queryDestination },
        })
          .then(({ data }) => {
            this.filteredOptionsDesti = [{ data: data.data }]
            this.optionDestination = data.data
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failed',
                text: 'Galat tambah data mitra gudang',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    handleDeleteImg(type) {
      switch (type) {
        case this.imageFieldFormType.ownerKTP:
          this.prevImg.ktp = null
          break
        case this.imageFieldFormType.fulfillmentLogo:
          this.prevImg.logo = null
          break
        default:
          break
      }
    },
    handleDeleteAllImgWarehouse() {
      this.changedImageData.warehouseDeleted = [...this.prevImg.warehouse]
      this.changedImageData.warehouse = []
      this.prevImg.warehouse = []
      this.dataFulfillment.image_warehouse = []
    },
    handleDeleteImgWarehouse(indexdt, dtImg) {
      const data = `${indexdt}`
      if (data) {
        const oldData = {
          prevImg: [...this.prevImg.warehouse],
          dataFulfillment: [...this.dataFulfillment.image_warehouse],
        }
        const newData = {
          prevImg: oldData.prevImg.filter((_, indx) => indx !== indexdt),
          dataFulfillment: oldData.dataFulfillment.filter(
            (_, indx) => indx !== indexdt,
          ),
        }
        this.prevImg.warehouse = newData.prevImg
        this.changedImageData.warehouse = newData.dataFulfillment.filter(
          x => x instanceof File,
        )
        this.dataFulfillment.image_warehouse = newData.dataFulfillment
        if (this.isValidHttpUrl(dtImg)) {
          this.changedImageData.warehouseDeleted.push(dtImg)
        }
      } else {
        this.prevImg.warehouse = []
        this.dataFulfillment.image_warehouse = []
      }
    },
    validateSize(dt) {
      // in MiB
      const fileSize = dt.size / 1024 / 1024
      if (fileSize > 2) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text: 'Ukuran berkas tidak boleh lebih dari 2 MB',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    isValidHttpUrl(string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31
        && (charCode < 48 || charCode > 57)
        && charCode !== 48
      ) {
        evt.preventDefault()
      }
      return true
    },
    isAlphabet(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z]+$/.test(char)) {
        return true
      }
      if (/\s/.test(char)) {
        return true
      }
      e.preventDefault()
      return false
    },
    validationSubmit() {
      let result = true
      if (
        this.dataAkun.email === ''
        || this.dataAkun.username === ''
        || this.dataFulfillment.warehouse_name === ''
        || this.dataFulfillment.avability === null
        || this.dataFulfillment.pic_name === ''
        || this.dataFulfillment.pic_phone === ''
        || this.dataFulfillment.description === ''
        || this.prevImg.warehouse.length === 0
        || this.prevImg.logo === null
        || this.prevImg.ktp === null
        || this.dataOwner.owner === ''
        || this.dataOwner.gender === null
        || this.dataOwner.phone_number === ''
        || this.dataOwner.nik === ''
        || this.dataOwner.image_ktp_url === ''
        || this.dataProperti.destination_id === ''
        || this.dataProperti.detail_addres === ''
        || this.dataProperti.building_area === ''
        || this.dataProperti.building_type === null
        || this.dataProperti.ownership === null
      ) {
        result = true
      } else {
        result = false
      }
      return result
    },
    async checkWhatsapp(phone) {
      await komshipAxiosIns
        .post(`/v1/check-wa?phone_no=${phone}`)
        .then(res => {
          const { data } = res.data
          this.isWhatsapp = data
          this.messageErrorPhone = false
        })
        .catch(error => {
          const { data } = error.response.data
          this.isWhatsapp = data
          this.messageErrorPhone = false
          if (error.response.data.code !== 1001) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            })
          }
        })
    },
    formatPhoneCustomer: _.debounce(function () {
      if (this.customerPhone.length < 9) {
        this.messageErrorPhone = true
        this.requireCustomerPhone = true
        this.isWhatsapp = null
      } else {
        this.messageErrorPhone = false
        this.requireCustomerPhone = false
      }
      if (this.customerPhonePasteMode === true) {
        this.customerPhone = this.customerPhonePaste
      }
      this.customerPhonePasteMode = false
    }, 1000),
    valueFormatPhone(e) {
      if (e.target.value.length < 9) {
        this.messageErrorPhone = true
      } else {
        this.messageErrorPhone = false
      }
    },
    checkValidationCustomerPhone(e) {
      if (e.target.value.length < 9) {
        this.requireCustomerPhone = true
        this.messageErrorPhone = true
      } else {
        this.requireCustomerPhone = false
        this.customerPhone = Number(e.target.value)
        this.checkWhatsapp(this.customerPhone)
      }
    },
    formatterPhone(e) {
      this.customerPhonePasteMode = true
      this.customerPhone = ''
      this.customerPhonePaste = ''
      this.customerPhonePaste = e.clipboardData
        .getData('text')
        .replace(/\D/g, '')
      if (this.customerPhonePaste.charAt(0) === '0') {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          1,
          this.customerPhonePaste.length,
        )
      } else {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          0,
          this.customerPhonePaste.length,
        )
      }
      if (this.customerPhonePaste.charAt(0) === '6') {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          2,
          this.customerPhonePaste.length,
        )
      } else {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          0,
          this.customerPhonePaste.length,
        )
      }
    },
    validateInputPhoneCustomer(e) {
      const charCode = e.which ? e.which : e.keyCode
      if (e.target.value.length > 13) {
        e.preventDefault()
      }
      if (charCode === 101 || charCode === 46 || charCode === 44) {
        e.preventDefault()
      }
      return true
    },
    async verifyAccount(value) {
      this.loadingVerifEmail = true
      this.disableVerifEmail = true
      const url = `/kompack/warehouse/resend-email/${this.$route.params.id}`
      await axiosIns.post(url).then(() => {
        this.disableVerifEmail = true
        this.loadingVerifEmail = false
        this.$toast(
          {
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              text: 'Silahkan periksa email anda untuk verifikasi akun',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          { timeout: 4000 },
        )
      })
    },
  },
}
